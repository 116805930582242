.login-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;

  & > div {
    width: 100%;
    max-width: 218px;
    .ant-legacy-form-item-control-wrapper {
      width: 100%;
      .ant-legacy-form-item-control {
        width: 100%;
        .ant-legacy-form-item-children {
          width: 100%;
          display: flex;
          justify-content: center;
          button {
            width: calc(100% - 4px);
          }
        }
      }
    }
  }

  .error-message {
    color: red;
    margin-top: 0px;
    font-size: 12px;
    line-height: 12px;
  }
}
