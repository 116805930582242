.table-col-setting {
	position: absolute;
	z-index: 10;
	right: -13px;
	top: -13px;

	@media (max-width: 800px) {
		top: -15px;
		right: -15px;
	}

	.setting-card {
		display: flex;
		flex-direction: column;
		gap: 10px;
		position: absolute;
		padding: 20px;
		right: 10px;
		border: 1px solid #e8e8e8;
		box-sizing: border-box;
		color: rgba(0, 0, 0, 0.65);
		background: #fff;
		button {
			width: max-content;
		}
	}

	.ant-checkbox-wrapper {
		display: flex;
		flex-direction: row;
		align-items: center;
		// .ant-checkbox {
		// 	padding-top: 5px;
		// }
		span {
			white-space: nowrap;
		}
	}
}
