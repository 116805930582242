@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.has-error {
  position: relative;
}

.has-error .ant-form-explain {
  position: absolute;
  top: 35px;
}

.ant-form .ant-form-item {
  margin-bottom: 10px;
}
.ant-form-item-with-help {
  margin-bottom: 10px;
}

/* .ant-table-body .ant-table-tbody > tr > td {
  padding: 0 2px 0 2px;
  
} */

/* .ant-table-body .ant-select-selection {
  border-radius: 0;
}

.ant-table-body .ant-table-tbody > tr > td input {
  border-radius: 0;
} */

.ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
  vertical-align: middle !important;
  /* padding-top: 3px; */
}

/* .ant-table-tbody .ant-select-selection__rendered {
  margin-right: 3px;
  margin-left: 3px;
}

.ant-table-tbody .ant-form-item-control {
  line-height: 30px;
} */

.ant-layout-sider-children {
  background: #fff;
}

body .ant-layout-header {
  padding: 0px 20px;
}

