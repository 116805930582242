.defaultValues-form {
  .flex-column {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .ant-legacy-form-item-control-wrapper {
      width: 100%;
    }
    .ant-legacy-form-item-control {
      width: 100%;
      .ant-select {
        width: 100%;
      }
    }
  }
  .ant-legacy-form-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}