.createProduct-form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .productType-container {
    & > * {
      margin: 0;
      .ant-form-item-label {
        text-align: left;
      }
    }
  }
  
  .ant-row {
    display: flex;
    flex-direction: column;
    .ant-legacy-form-explain {
      color: #ff4d4f;
    }
    .ant-select {
      width: 100%;
    }
    .ant-checkbox-wrapper {
      margin-left: 10px;
    }
  }
}