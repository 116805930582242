// .create-row {
//     background: #f6ffed;
// }

.purchase-order-form {
    flex-direction: column;

    & > .ant-row {
        padding-right: 0;
    }
    .ant-form-item {
        margin-bottom: 15px;
    }
    .ant-form-item-label {
        line-height: 20px;
    }
}