.row-filter-container {
	& > * {
		width: 100%;
	}
}

.filter-form {
	position: relative;

	& > .ant-row {
 
		.ant-legacy-form-item-label, .ant-form-item-label {
			display: flex;
			align-items: center;
			margin-right: 10px;
		}

		.ant-form-item {
			margin-right: 16px;
		}
	}

	@media (max-width: 1024px) {
		display: flex;
		flex-direction: column;
		& > .ant-row {
			width: 100%;
			padding-right: 12%;
			margin-bottom: 16px;
			gap: 16px !important;
			.ant-form-item {
				margin-right: 0;
			}
			& > .ant-row {
				// width: 47%;
				margin: 0;
				margin-right: 3%;
				& > .ant-form-item-control-wrapper {
					width: 100%;
				}
			}

			.ant-calendar-picker,
			.ant-select {
				margin-left: 0 !important;
			}

			.custom-date-picker {
				display: flex;
				margin-right: -12%;
				width: 112%;

				.ant-calendar-picker-icon {
					right: 12.5%;
				}
				.ant-form-item-children {
					width: 100%;
					display: inline-block;
					.date-picker-from,
					.date-picker-to {
						width: 45%;
						padding-right: 3%;
					}

					.date-picker-period {
						width: 8%;
						padding: 0;
						// min-width: 30px;
					}
				}
			}
		}
	}

	@media (max-width: 780px) {
		& > .ant-row {
			.custom-date-picker {
				.ant-form-item-control-input-content {
					.ant-select {
						margin-top: 16px;
					}
				}
			}
		}
	}

	@media (max-width: 628px) {
		& > .ant-row {
			.custom-date-picker {
				.ant-form-item-control-input-content {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					gap: 16px;
					.ant-picker {
						margin: 0 !important;
						max-width: 168px;
						width: 100%;
					}
					.ant-select {
						margin-top: 0px;
					}
				}
			}
		}
	}

	.reset-container {
		.ant-form-item-control-wrapper {
			width: auto;
		}
		.ant-form-item-control {
			display: flex;
			justify-content: flex-end;
		}
	}
}

.ant-select-dropdown {
	width: auto !important;
	min-width: 100px;
}

.ant-modal-body {
	& > .ant-row {

		& > * {
			width: 100%;
		}

		.filter-form {
			display: flex;
			flex-direction: column;
	
			.ant-legacy-form-item {
				margin-bottom: 16px;
				display: flex;
				flex-direction: column;
			}
	
			@media (max-width: 1024px) {
				display: flex;
				flex-direction: column;
				& > .ant-row {
					width: 100%;
					margin-bottom: 0px !important;
					gap: 0px !important;
				}
				& > .ant-legacy-form-item {
					margin-bottom: 16px !important;
				}
			}
		}
	}

}
