.color-picker {
    position: relative;
    // height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
	.swatch {
		padding: 5px;
		background: #fff;
		border-radius: 1px;
		box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
		display: inline-block;
        cursor: pointer;
        div {
            width: 36px;
            height: 14px;
            border-radius: 2px;
        }
	}
	.popover {
		position: absolute;
        z-index: 2;
        left : -200px;
        top: 10px;
	}
	.cover {
		position: fixed;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
    }
    
    &.disabled {
        .swatch {
            cursor: default;
        }
    }
}
